<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Inventaire mensuel / Jour / Site</p>
    </div>
    <v-card class="mb-6 pa-2">
      <v-card-text>
        <div class="d-flex flex-row mb-6">
          <v-text-field
            v-model="searchStr"
            label="Rechercher une date"
            @input="search"
            class="mx-2"
            dense
          />
          <v-text-field
            v-model="searchColStr"
            label="Rechercher une colonne"
            @input="search"
            class="mx-2"
            dense
          />
          <v-spacer></v-spacer>
          <v-autocomplete
            v-model="selectedSiteId"
            :items="sitesList"
            :menu-props="{ maxHeight: '400', zIndex: 13 }"
            label="Sélectionner un site"
            item-text="name"
            item-value="id"
            @change="changeSite"
            dense="dense"
            :useGetParams="true"
          />
          <date-picker-single-month
            identifier="monthFilter"
            label="Sélectionner un mois"
            dateInit="yesterday"
            @onSelect="setSelectedMonth"
            dense="dense"
            customClass="ml-4"
            hint=""
            :useGetParams="true"
          />
          <v-btn
            v-show="updateCacheIsShowed"
            @click="debouncedGetData()"
            outlined
            dense
            class="mx-1"
          >
            Reload
          </v-btn>
          <v-btn
            v-show="updateCacheIsShowed"
            @click="
              updateCache = true;
              debouncedGetData();
            "
            outlined
            dense
            class="mx-1"
          >
            No cache
          </v-btn>
          <dot-menu
            @exportExcel="exportExcel"
            :excelButton="{
              excelData,
              filename: `Prog-Inventory-month-${this.monthSelected}-${this.selectedSiteCode}.xls`,
              disabled: false,
            }"
          />
        </div>
        <v-progress-linear v-show="loading" indeterminate :height="5" />
        <div v-if="filteredData.length !== 0">
          <v-simple-table
            dense
            fixed-header
            height="500"
            class="table-inventory"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(value, key, index) in filteredData[0]"
                    :key="index"
                    class="text-left"
                    :class="`field_${key}`"
                  >
                    {{ key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) in filteredData"
                  :key="i"
                  :class="
                    i % 2 === 0 ? 'row-inventory-odd' : 'row-inventory-even'
                  "
                >
                  <td
                    v-for="(value, key, index) in item"
                    :key="index"
                    class="cell"
                    :class="`value_${key}`"
                  >
                    <span v-html="displayFormat(value)"></span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-else>
          {{ noDataMessage }}
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-title>Options d'affichage</v-card-title>
      <v-card-text>
        <v-row class="ma-3">
          <v-col cols="12">
            <v-switch
              v-model="displayPrettyNumbers"
              label="Afficher les grands nombres plus lisibles"
            ></v-switch>
            <v-switch
              v-model="displayLessDecimals"
              label="Afficher moins de décimales"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import store from "@/store";
import debounce from "lodash/debounce";

import DotMenu from "@/components/common/menus/DotMenu";
import DatePickerSingleMonth from "@/components/common/filters/DatePickerSingleMonth";
import { dataLoadingText, noDataText } from "@/utils/constants";
import { prettyNbs, round2Dec } from "@/utils/formatting";
import { Konami, KonamiManager } from "@/utils/konami";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "SitesPartnerViewsByDate",
  props: {},
  components: {
    DatePickerSingleMonth,
    DotMenu,
  },
  watch: {},
  async created() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "fullWidth");
    this.sitesList = await this.$store.dispatch("common/getSiteList");
    const urlParams = new URLSearchParams(window.location.search);
    const siteIdParam = urlParams.get("site_id");
    if (siteIdParam) {
      if (/^\d+$/.test(siteIdParam)) {
        this.selectedSiteId = parseInt(siteIdParam, 10);
      }
    }
    if (!this.selectedSiteId) {
      this.selectedSiteId = 2;
    }
    this.debouncedGetData();
    this.konamiManager = new KonamiManager(this, [
      new Konami([68, 69, 86], () => {
        this.updateCacheIsShowed = true; // d e v
      }),
    ]);
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "boxed");
  },
  data() {
    return {
      updateCache: false,
      updateCacheIsShowed: false,
      loading: true,
      sitesList: null,
      selectedSiteId: null,
      monthFlag: false,
      monthSelected: null,
      filteredData: [],
      searchStr: "",
      searchColStr: "",
      fixedCols: ["date", "ga_sessions"],
      searchTimeout: null,
      noDataMessage: dataLoadingText,
      data: {
        /*

      */
      },
      excelData: new Blob([""], { type: "text/plain" }),
      filteredData: {},
      displayPrettyNumbers: true,
      displayLessDecimals: true,
    };
  },
  methods: {
    displayFormat(originalValue) {
      let value = originalValue;
      if (typeof value === "number") {
        value = Number(value);
        if (Number.isInteger(value)) {
          value = parseInt(value);
          if (this.displayPrettyNumbers) {
            value = prettyNbs(value);
          }
        } else if (parseFloat(value) === value) {
          value = parseFloat(value);
          if (this.displayLessDecimals) {
            value = round2Dec(value);
          }
        }
      }
      return value;
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    changeSite() {
      const url = new URL(window.location.href);
      url.searchParams.set("site_id", this.selectedSiteId);
      window.history.replaceState(null, null, url.toString());
      this.debouncedGetData();
    },
    setSelectedMonth(month) {
      this.monthSelected = month;
      this.debouncedGetData();
    },
    async exportExcel() {
      if (!this.monthSelected) {
        alert("missing params");
        return false;
      }
      const url = "/programmatic/inventory/export/xls";
      const queryParams = {
        site_id: this.selectedSiteId,
        month: this.monthSelected,
      };
      this.loading = true;
      const { data } = await axios.get(url, {
        params: queryParams,
        responseType: "blob",
      });
      this.loading = false;
      this.excelData = data;
    },
    prettyNbs(x, sep = " ") {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    },
    async getData() {
      if (!this.selectedSiteId) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Veuillez sélectionner un site.`,
          color: "error",
          timeout: 5000,
        });
        return;
      }
      this.data = {};
      this.filteredData = {};
      this.noDataMessage = dataLoadingText;
      const url = "/programmatic/inventory";
      const queryParams = {
        site_id: this.selectedSiteId,
        month: this.monthSelected,
        update_cache: this.updateCache,
      };
      this.loading = true;
      try {
        const {
          data: { error, items },
        } = await axios.get(url, { params: queryParams });

        this.loading = false;
        if (error) {
          this.noDataMessage = error;
          return;
        }
        if (items.length == 0) {
          this.noDataMessage = noDataText;
          return;
        }
        // JSON.parse(JSON.stringify(response.data));
        this.data = items;
        this.setFilteredData();
      } catch (error) {
        this.loading = false;
        this.noDataMessage = error;
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Une erreur serveur est survenue: ${error}`,
          color: "error",
          timeout: 5000,
        });
        return;
      }
    },
    search() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        this.setFilteredData();
      }, 500);
    },
    setFilteredData() {
      const filteredData = [];
      const searchStrLC = this.searchStr.trim().toLowerCase();
      const searchColStrLC = this.searchColStr.trim().toLowerCase();
      for (let i = 0; i < this.data.length; i++) {
        if (
          this.searchStr == "" ||
          this.data[i]["date"].toLowerCase().includes(searchStrLC)
        ) {
          //let obj = this.data[i];
          let obj = structuredClone(this.data[i]);

          if (searchColStrLC != "") {
            obj = {};
            for (const property in this.data[i]) {
              if (
                this.fixedCols.includes(property) ||
                property.toLowerCase().includes(searchColStrLC)
              ) {
                obj[property] = this.data[i][property];
              }
            }
          }
          filteredData.push(obj);
        }
      }
      this.filteredData = filteredData;
    },
    dateToStr(date) {
      return date.toISOString().split("T")[0];
    },
  },
  computed: {
    selectedSiteCode() {
      let siteCode = "";
      if (this.sitesList && this.sitesList.length && this.selectedSiteId) {
        let site = this.sitesList.find((x) => x.id === this.selectedSiteId);
        siteCode = site.code;
      }
      return siteCode;
    },
  },
  mounted() {
    // this.debouncedGetData();
    saveRmraView(this.$route);
  },
  filters: {},
};
</script>
<style>
.table-inventory table {
  border-right: thin solid rgba(94, 86, 105, 0.14) !important;
  border-bottom: thin solid rgba(94, 86, 105, 0.14) !important;
}
.row-inventory-even,
.row-inventory-even .value_date {
  background-color: #fbfafb !important;
}
</style>
<style scoped>
.select-year {
  width: 30px;
}
.select-year {
  width: 250px;
}
th {
  text-transform: capitalize;
  font-size: 11px;
}
.cell span {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.cell {
  text-align: right;
}
td.cell span {
  font-size: 0.8em;
  text-align: right;
}
td {
  z-index: 0;
}
.field_date,
.value_date {
  position: sticky;
  width: 5em;
  left: 0;
  top: auto;
  z-index: 12 !important;
  background-color: white;
  border-left: thin solid rgba(94, 86, 105, 0.14);
  border-right: thin solid rgba(94, 86, 105, 0.14);
}
</style>
